.auth-layout {
  width: 100%;
  height: 100%;
  @media (min-width: 768px) {
    height: 100vh;
  }
  .ant-col {
    padding: 2rem;

    @media (min-width: 991px) {
      padding: 3rem 5.635rem;
    }
  }
}
.left-top-image {
  background: url(../../assets/img/auth/left-top.svg) no-repeat;
  position: absolute;
  width: 154px;
  height: 193px;
  left: -72px;
  top: -11px;
  background-size: 100%;
}

.right-top-image {
  background: url(../../assets/img/auth/right-top.svg) no-repeat;
  width: 171px;
  height: 265px;
  background-size: 100%;
  top: -24px;
  right: -10px;
  position: absolute;
}

.left-bottom-image {
  background: url(../../assets/img/auth/left-bottom.svg) no-repeat;
  position: absolute;
  background-size: 100%;
  width: 214px;
  height: 282px;
  left: -72px;
  bottom: -20px;
}

.right-bottom-image {
  background: url(../../assets/img/auth/right-bottom.svg) no-repeat;
  background-size: 100%;
  width: 190px;
  height: 168px;
  position: absolute;
  @media (min-width: 991px) {
    right: 0;
    bottom: -23px;
  }
}
.auth-text-holder {
  position: relative;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
}
