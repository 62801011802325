$speed: 2s;

.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1051;
  display: flex;
  align-items: center;
  justify-content: center;
  //   display: none;
  svg {
    width: 200px;
  }
}
.loading {
  display: block;
  margin: auto;
  position: relative;
  width: 60px;
  height: 60px;

  span {
    display: block;
    position: absolute;
    width: 50%;
    height: 50%;

    &:nth-child(1) {
      top: 0;
      left: 0;

      background-color: rgba(#fba306, 0.75);

      animation: move1 $speed linear infinite;
    }
    &:nth-child(2) {
      top: 0;
      right: 0;

      background-color: rgba(#1dd0f8, 0.75);

      animation: move2 $speed linear infinite;
    }
    &:nth-child(3) {
      bottom: 0;
      left: 0;

      background-color: rgba(#013765, 0.75);

      animation: move3 $speed linear infinite;
    }
    &:nth-child(4) {
      bottom: 0;
      right: 0;

      background-color: rgba(#ff577a, 0.75);

      animation: move4 $speed linear infinite;
    }
  }
}

@keyframes move1 {
  0% {
    transform: translate(0);
    z-index: 10;
  }
  25% {
    transform: translate(100%, 0);
  }
  50% {
    transform: translate(100%, 100%);
  }
  75% {
    transform: translate(0, 100%);
    z-index: 0;
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move2 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(0, 100%);
  }
  50% {
    transform: translate(-100%, 100%);
    z-index: 0;
  }
  75% {
    transform: translate(-100%, 0);
    z-index: 10;
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move3 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(0, -100%);
    z-index: 10;
  }
  50% {
    transform: translate(100%, -100%);
  }
  75% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0);
    z-index: 0;
  }
}
@keyframes move4 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-100%, 0);
    z-index: 0;
  }
  50% {
    transform: translate(-100%, -100%);
    z-index: 10;
  }
  75% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0);
  }
}
