$prime-color: #1c64f2;
$font: #202d50;
.cms-webview-wrapper {
  &:not(.preview) {
    height: 100vh;
    overflow: auto;
    .cms-preview-container {
      min-height: 100%;
    }
  }
  .cms-preview-container {
    background-color: #ffffff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    .cms-pagination {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
      position: relative;
      .cms-counter {
        position: absolute;
        bottom: 10px;
        right: 37px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $text-gray;
      }
      .filled {
        height: 4px;
        background-color: $prime-color;
        border-radius: 30px;
        cursor: pointer;
      }
      .unfilled {
        cursor: pointer;
        height: 4px;
        background-color: #d8e1e5;
        border-radius: 30px;
      }
    }
    .cms-unit-details {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;
      .unit-title {
        font-weight: 600;
        font-size: 19px;
        line-height: 28px;
        color: $prime-color;
      }
      .unit-position {
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #828282;
      }
    }
    .cms-title {
      width: 100%;
      font-weight: 600;
      font-size: 19px;
      line-height: 28px;
      color: $font;
      margin-bottom: 1.5rem;
    }
    .cms-subtitle {
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      color: $font;
      width: 100%;
      margin-bottom: 0.5rem;
    }
    .cms-media-label {
      margin-bottom: 0.5rem;
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      color: $font;
    }
    .cms-picture {
      width: 100%;
      margin-bottom: 1.5rem;
      img {
        border-radius: 8px;
        width: 100%;
        max-height: 210px;
        object-fit: cover;
      }
    }
    .cms-video {
      margin-bottom: 1.5rem;
      .education-video {
        max-height: 210px;
      }
    }
    .cms-text {
      width: 100%;
      margin-bottom: 1.5rem;
      .ql-editor {
        padding: 0px;
      }
      .ql-container.ql-disabled .ql-tooltip {
        display: none;
      }
    }
    .cms-resources {
      margin-bottom: 1.5rem;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      a {
        color: $prime-color;
        word-break: break-all;
      }
      a:hover {
        border-bottom: 1px solid $prime-color;
      }
    }
    .cms-question {
      .question-container {
        .question-title {
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          color: $font;
        }
      }
      .slider-image {
        max-height: 250px;
        max-width: 250px;
        object-fit: contain;
        border-radius: 16px;
      }
      .cms-slider {
        .ant-slider-track {
          background-color: $prime-color;
        }
        &.ant-slider .ant-slider-dot.ant-slider-dot-active {
          border-color: $prime-color;
        }
        .ant-slider-handle {
          border-color: $prime-color;
          box-shadow: 0 0 0 2px rgba(28, 100, 242, 0.12);
        }
        .ant-slider-mark {
          .ant-slider-mark-text:first-child {
            left: 20px !important;
          }
        }
      }
      .cms-button {
        border-radius: 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        background-color: $prime-color;
        border-color: $prime-color;
        height: 40px;
      }
      .question-choices {
        display: flex;
        flex-direction: column;
        .question-choice-image {
          width: 30px;
          height: 30px;
          object-fit: contain;
          margin-right: 15px;
        }
        .text-field,
        .input-field {
          resize: none;
          background: #f9fafb;
          border: 1px solid #e5e7eb;
          border-radius: 16px;
          &.ant-input:hover,
          &.ant-input:focus {
            border-color: $prime-color;
            box-shadow: 0 0 0 2px rgba(28, 100, 242, 0.2);
          }
        }
        .question-options {
          border: 1px solid #d8e1e5;
          border-radius: 4px;
          width: 100%;
          padding: 10px;
          font-weight: 500;
          &.ant-checkbox-wrapper-checked,
          &.ant-radio-wrapper-checked {
            background-color: rgba(28, 100, 242, 0.1);
          }
          &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
          .ant-checkbox:hover .ant-checkbox-inner,
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: $prime-color;
          }
          .ant-checkbox-checked::after {
            border-color: $prime-color;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $prime-color;
            border-color: $prime-color;
          }
          .ant-radio-checked .ant-radio-inner {
            &::after {
              background-color: $prime-color;
            }
            border-color: $prime-color;
          }
          &.ant-radio-wrapper:hover .ant-radio,
          .ant-radio:hover .ant-radio-inner,
          .ant-radio-input:focus + .ant-radio-inner {
            border-color: $prime-color;
          }
          &.ant-radio-wrapper .ant-radio-checked::after {
            border-color: $prime-color;
          }
          .ant-radio-input:focus + .ant-radio-inner {
            box-shadow: 0 0 0 3px rgba(28, 100, 242, 0.1);
          }
        }
        .ant-checkbox-wrapper,
        .ant-radio-wrapper {
          margin: 0px;
          margin-bottom: 20px;
        }
      }
      .explanation {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        &.correct {
          color: #31c48d;
        }
        &.wrong {
          color: #f05252;
        }
      }
    }
    .button-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      .action {
        cursor: pointer;
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
        color: $prime-color;
        .action-icon {
          color: $prime-color;
        }
      }
      .action.disabled {
        cursor: not-allowed;
        color: #4f4f4f;
        .action-icon {
          color: #4f4f4f;
        }
      }
    }
  }
  .cms-highlight {
    background: #22de99;
    border-radius: 8px;
    color: white;
    padding: 2px;
    position: relative;
    .highlight-text {
      z-index: 10;
      background: #22de99;
      border-radius: 8px;
      padding: 14px 20px;
      position: absolute;
      top: 24px;
      max-width: calc(100vw - 32px);
      .highlight-header {
        display: flex;
        min-width: 250px;
      }
      .highlight-close {
        margin-left: auto;
        height: 16px;
        width: 16px;
        cursor: pointer;
      }
      .title {
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
      }
      .description {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
}

@media screen and (min-width: 801px) {
  .cms-webview-wrapper {
    display: flex;
    justify-content: center;
    padding: 50px 0px;
    align-items: flex-start;
    .cms-preview-container {
      min-width: 801px;
      max-width: 850px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 50px 0px;
      .cms-pagination {
        padding: 0px 37px;
      }
      .cms-unit-details {
        padding: 0px 37px;
      }
      .cms-title {
        padding: 0px 37px;
      }
      .cms-subtitle {
        padding: 0px 37px;
      }
      .cms-picture {
        img {
          max-height: 60vh;
        }
        padding: 0px 37px;
      }
      .cms-video {
        padding: 0px 37px;
        .education-video {
          max-height: 60vh;
        }
      }
      .cms-text {
        padding: 0px 37px;
        margin-bottom: 1.5rem;
      }
      .cms-resources {
        padding: 0px 37px;
        margin-bottom: 1.5rem;
      }
      .cms-question {
        padding: 0px 37px;
      }
      .button-container {
        padding: 10px 37px 0px 37px;
      }
    }
  }
}
@media screen and (max-width: 801px) {
  .timer-modal {
    margin: 0px auto !important;
    width: calc(100vw - 32px) !important ;
    max-width: 350px !important ;
  }
}
.timer-modal {
  max-width: 360px;
  .ant-modal-content {
    border-radius: 12px;
  }
  .timer-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .timer-title {
      font-weight: 600;
      font-size: 17px;
      line-height: 150%;
      color: $font;
      margin: 40px 0px 30px 0px;
      display: flex;
      align-items: center;
      .ant-checkbox {
        margin-right: 15px;
        .ant-checkbox-inner {
          background-color: $prime-color;
          border-color: $prime-color;
          border-radius: 50%;
        }
      }
    }
    .cms-button {
      border-radius: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      height: 40px;
    }
    .cms-button:not([disabled]) {
      background-color: $prime-color;
      border-color: $prime-color;
    }
    .timer-subtitle {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #6b7280;
      margin-bottom: 30px;
    }
    .timer-image {
      width: 100%;
      max-height: 170px;
      margin-bottom: 30px;
      object-fit: contain;
    }
    .timer-countdown {
      position: absolute;
      top: -70px;
      height: 90px;
      width: 90px;
      border-radius: 50%;
      background-color: #f3f4f5;
      .ant-statistic {
        position: relative;
        top: -100%;
        height: 90px;
        width: 90px;
        display: flex;
        justify-self: center;
        align-items: center;
        .ant-statistic-content {
          width: 100%;
          text-align: center;
          font-weight: 600;
          font-size: 21px;
          line-height: 150%;
          color: #1c64f2;
        }
      }
    }
  }
}
