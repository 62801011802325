.cms-builder-wrapper {
  margin: -2.5rem;
  padding-top: 80px;
  .cms-builder-content {
    overflow: auto;
    height: calc(100vh - 140px);
  }
}
.cms-section-builder {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  position: relative;
  .division {
    flex: 1;
    min-width: 1px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 16px;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
      color: #1f2a37;
      margin-bottom: 10px;
    }
    .subtitle {
      font-size: 14px;
      line-height: 20px;
      color: $text-gray;
      margin-bottom: 40px;
    }
    .sub-division {
      .name {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: $text-color-2;
        margin-bottom: 5px;
      }
      .desc {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: $text-gray;
      }
    }
    .cms-type-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      column-gap: 20px;
      row-gap: 20px;
      margin-bottom: 20px;
      .cms-type {
        cursor: pointer;
        flex: 1;
        height: 175px;
        background: #ffffff;
        border: 1px solid $border-color;
        border-radius: 10px;
        padding: 16px;
        .icon {
          margin-bottom: 10px;
        }
      }
      .cms-type.selected {
        background-color: $primary-color;
        .name,
        .desc {
          color: #ffffff;
        }
        .cms-icon {
          path {
            fill: #ffffff;
          }
        }
      }
    }
    .section-container {
      width: 100%;
      flex: 1;
      min-height: 0px;
      .section {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        height: 81px;
        display: flex;
        margin-bottom: 16px;
        padding: 16px 23px;
        background: #ffffff;
        border: 1px solid $border-color;
        border-radius: 10px;
        .shuffle-handler {
          margin-right: 15px;
        }
        .icon {
          margin-right: 15px;
        }
        .delete-icon {
          margin-left: auto;
        }
      }
    }
  }
  .division:first-child {
    margin-right: 20px;
    position: sticky;
    top: 20px;
  }
}
.cms-content-builder {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  .division {
    flex: 1;
    min-width: 1px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
      color: #1f2a37;
      margin-bottom: 40px;
    }
    .subtitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: $text-color-2;
    }
    .type-container {
      .type {
        display: flex;
        align-items: center;
        height: 44px;
        border-bottom: 1px solid #e4e4e7;
        .icon {
          margin-right: 10px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .content-item-wrapper {
      padding: 20px 16px;
      .action-item {
        width: 54px;
        display: flex;
        justify-content: center;
      }
      span.action-item {
        margin-bottom: 10px;
      }
      .education-question.input-wrapper {
        flex: 1;
        min-width: 1px;
      }
    }
    .content-item-wrapper:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
    .fr-wrapper {
      height: 250px;
      overflow: auto;
    }
  }
  .division:first-child {
    margin-right: 20px;
    max-width: 313px;
    padding: 16px;
  }
}

.dual-input {
  .input-wrapper {
    flex: 1;
    min-width: 1px;
  }
  .input-wrapper:first-child {
    margin-right: 15px;
  }
}
