.chat-container {
  padding: 24px;
  overflow: auto;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chat-holder {
  width: 100%;
  flex: 1;
  min-height: 0px;
  overflow: auto;
  scroll-behavior: smooth;
  border: 1px solid $border-color;
  background-color: #fff;
  border-radius: 16px 16px 0px 0px;
  .empty-chat {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $sidebar-bg;
  }
  .loading-container,
  .empty-chat {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .next-day-label {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .label {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 10px;
      background-color: $primary-color;
      border-radius: 8px;
      color: #fff;
    }
  }
  .chat-section {
    background: #f2f2f2;
    color: $sidebar-bg;
    border-radius: 8px 8px 8px 0;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.75rem;
    padding: 10px;
    max-width: 50%;
    width: fit-content;
  }
  .user-name-text {
    font-weight: 600;
    text-align: left;
    color: $sidebar-bg;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 8px;
  }
  .time-text {
    text-align: left;
    color: $text-muted;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 8px;
  }
  .chat-others {
    display: block;
    margin-bottom: 16px;
  }
  .chat-attachment-wrapper {
    width: 50%;
    background: #f2f2f2;
    border-radius: 8px 8px 8px 0;
    overflow: hidden;
    text-align: center;
    .user-name-text {
      padding: 10px;
    }
    .time-text {
      padding: 10px;
    }
    .attachment-image {
      max-width: 100%;
      object-fit: contain;
    }
    .attachment-file {
      margin: 10px;
      background-color: #fff;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .attachment-file-name {
        flex: 1;
        min-width: 0px;
        margin-right: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
    .attachment-text {
      width: 100%;
      background-color: #f2f2f2;
      color: $sidebar-bg;
      text-align: left;
    }
  }
  .chat-me {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .chat-section {
      border-radius: 8px 8px 0 8px;
    }
    .chat-attachment-wrapper {
      border-radius: 8px 8px 0 8px;
    }
  }
  .show-more {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: $primary-color;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .chat-link {
    text-decoration: underline;
    cursor: pointer;
  }
}
.chat-editor {
  height: 54px;
  width: 100%;
  background-color: #f2f2f2;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px 0px 16px 16px;
  .chat-input {
    flex: 1;
    min-width: 0px;
    resize: none;
  }
  .chat-input-actions {
    margin-left: 10px;
    .link-wrapper {
      cursor: pointer;
    }
  }
}
.chat-attachment {
  width: 100%;
  min-height: 50px;
  padding: 10px;
  background-color: #f2f2f2;
  .chat-attachment-content {
    border-radius: 16px;
    background-color: #fff;
    height: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $sidebar-bg;
      margin-right: 10px;
    }
    .file-name {
      flex: 1;
      min-width: 0px;
      font-size: 14px;
      line-height: 18px;
      color: $sidebar-bg;
    }
    .attachment-actions {
      display: flex;
      align-items: center;
      margin-left: auto;
      .action-icon {
        color: #bdbdbd;
        margin-left: 5px;
      }
    }
  }
  .chat-attachment-media {
    position: relative;
    height: 250px;
    width: 350px;
    margin-left: auto;
    background-color: #f2f2f2;
    .close-icon {
      position: absolute;
      cursor: pointer;
      top: 15px;
      right: 15px;
      width: 25px;
      height: 25px;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      font-size: 12px;
      border-radius: 50%;
      text-align: center;
      z-index: 2;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.food-item {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  .ant-image-mask-info {
    display: flex;
    align-items: center;
  }
}
